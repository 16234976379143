//
// Utilities
// ---------

//
// Spacing
// -------
//
$spacer: 0.25rem; // 4px
$steps: 10; // from 0px through 4, 8, etc up to 40px (2.5rem)
$spacing-utils: (
  p: (
    "padding-top",
    "padding-right",
    "padding-bottom",
    "padding-left",
  ),
  pt: (
    "padding-top",
  ),
  pr: (
    "padding-right",
  ),
  pb: (
    "padding-bottom",
  ),
  pl: (
    "padding-left",
  ),
  px: (
    "padding-left",
    "padding-right",
  ),
  py: (
    "padding-top",
    "padding-bottom",
  ),
  m: (
    "margin-top",
    "margin-right",
    "margin-bottom",
    "margin-left",
  ),
  mt: (
    "margin-top",
  ),
  mr: (
    "margin-right",
  ),
  mb: (
    "margin-bottom",
  ),
  ml: (
    "margin-left",
  ),
  mx: (
    "margin-left",
    "margin-right",
  ),
  my: (
    "margin-top",
    "margin-bottom",
  ),
);

// Example: pt-4, mb-2, p-0
@each $selector, $props in $spacing-utils {
  .#{$selector} {
    @for $step from 0 to $steps {
      &-#{$step} {
        @each $property in $props {
          #{$property}: $step * $spacer !important;
        }
      }
    }
  }
}

//
// Borders
// -------
//
.round {
  border-radius: 100% !important;
}

//
// Display
// -------
//
$displays: flex, block, inline, inline-block;

// Example: d-flex
@each $selector in $displays {
  .d-#{$selector} {
    display: $selector !important;
  }
}

//
// Flex system
// -----------
//
$justify-contents: (
  baseline: baseline,
  center: center,
  start: flex-start,
  end: flex-end,
  space-around: space-around,
  space-between: space-between,
  space-evenly: space-evenly,
);

// Example: flex--center, flex--start, flex--space-evenly
@each $selector, $value in $justify-contents {
  .flex--#{$selector} {
    display: flex;
    justify-content: $value;
    align-items: center;
  }
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

//
// Typography
// ----------
//

// Font weights
.text--light {
  font-weight: 200 !important;
}
.text--normal {
  font-weight: 400 !important;
}
.text--bold {
  font-weight: 700 !important;
}

// Font sizes, line heights, text
$sizes: base, xxs, xs, sm, md, lg, xl, xxl;

// Example: text--xs, text--lg
@each $size in $sizes {
  .text--#{$size} {
    font-size: var(--font-#{$size}) !important;
    line-height: var(--line-height-#{$size}) !important;
  }
}

// Example: font--base, font--xl
@each $size in $sizes {
  .font--#{$size} {
    font-size: var(--font-#{$size}) !important;
  }
}

// Example: line-height--base, line-height--xl
@each $size in $sizes {
  .line-height--#{$size} {
    line-height: var(--line-height-#{$size}) !important;
  }
}

.text-transform-none {
  text-transform: none !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  &--2 {
    @extend .text-ellipsis;
    -webkit-line-clamp: 2;
  }
}

//
// Colors and backgrounds
// ----------------------
//
$colors: primary, secondary, tertiary, success, warning, danger, dark, medium,
  light, "lightgrey", "darkgrey", "bluegrey", "greylight", "black", "white";

// Example: color--primary, color--danger, background--white
@each $color in $colors {
  .color--#{$color} {
    color: var(--color-#{$color}) !important;
  }

  .background--#{$color} {
    background-color: var(--color-#{$color}) !important;
    background: var(--color-#{$color}) !important;
  }
}

.background--none {
  background: none !important;
  --background: none !important;
}

//
// Misc
// ----
//
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.width-full {
  margin-left: -1rem;
  margin-right: -1rem;
}

.pointer {
  cursor: pointer;
}
