//
// Common styles
// -------------
// For various classes that doesn't fit anywhere else
//

.link {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.external-link {
  color: var(--ion-color-primary);

  &:hover {
    color: var(--ion-color-primary-shade);
  }

  &:focus {
    outline: none;
  }
}

.row {
  padding-top: 10px;
}

.container {
  height: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.back-button {
  display: inline-flex;
  line-height: 25px;
}
