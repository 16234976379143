// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
@import "./theme/color-definitions";
@import "./theme/common/common";
@import "./theme/common/utilities";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$uptown-unchained-admin-primary: mat-palette($md-primary);
$uptown-unchained-admin-accent: mat-palette($md-secondary);

// The warn palette is optional (defaults to red).
$uptown-unchained-admin-warn: mat-palette($md-tertiary);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$uptown-unchained-admin-theme: mat-light-theme(
  (
    color: (
      primary: $uptown-unchained-admin-primary,
      accent: $uptown-unchained-admin-accent,
      warn: $uptown-unchained-admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($uptown-unchained-admin-theme);

html {
  height: 100%;
  overflow-y: hidden;
}
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
